<template>
  <div class="contain">
    <div class="form table">
      <div class="table-button mb"><el-button type="primary" class="sheet-btn" @click="exportMergeTask" icon="el-icon-plus">导出</el-button></div>
      <el-table :data="tableData" :header-cell-style="{ background: '#F1F4F9', color: '#5E6D9B', fontWeight: '400', 'text-align':'center' }" v-loading="tableLoading" show-summary :sum-text="'总分'" :summary-method="getSummaries" ref="projectTable" row-key="id">
        <el-table-column type="index" label="序号" width="80" align="center"></el-table-column>
        <el-table-column prop="supervision_topic" label="督导主题" align="center" width="180"></el-table-column>
        <el-table-column prop="template_name" label="督导模板" align="center" width="150"></el-table-column>
        <el-table-column prop="total_score" label="总分" align="center" width="100"></el-table-column>
        <el-table-column prop="reception_range" label="接收范围" min-width="150" align="center"></el-table-column>
        <el-table-column prop="publish_name" label="发布人" width="100" align="center"></el-table-column>
        <el-table-column prop="weights" label="权重" align="center" width="100"></el-table-column>
        <el-table-column prop="score" label="得分" align="center"></el-table-column>
      </el-table>
      <!-- <l-table :columns="columns" :dataSource="tableData" :pagination="false" :show-summary="true" :sum-text="'总分'" :summary-method="getSummaries" :loading="tableLoading" :showXh="true" :height="'41.66667vw'" :autoHeight="false">
        <el-table-column prop="supervision_topic" label="督导主题" align="center" width="180" slot="supervision_topic"></el-table-column>
        <el-table-column prop="template_name" label="督导模板" align="center" width="150" slot="template_name"></el-table-column>
        <el-table-column prop="total_score" label="总分" align="center" width="100" slot="total_score"></el-table-column>
        <el-table-column prop="reception_range" label="接收范围" min-width="150" align="center" slot="reception_range" ></el-table-column>
        <el-table-column prop="publish_name" label="发布人" width="100" align="center" slot="publish_name" ></el-table-column>
        <el-table-column prop="weights" label="权重" align="center" width="100" slot="weights"></el-table-column>
        <el-table-column prop="score" label="得分" align="center"  slot="score"></el-table-column>
      </l-table> -->
    </div>
  </div>
</template>
<script>
export default {
  name: 'mergeTaskDetail',
  props: {
    detailData: {
      type: Object,
      default: null
    }
  },
  data(){
    return{
      pageFlag: false, //是否分页
      page: {
        num: 1,
        size: 10,
        total: 0,
      }, // 分页信息
      tableLoading: false,//列表loading
      columns: [
        {
          label: "督导主题",
          slotName: "supervision_topic",
        },
        {
          label: "督导模板",
          slotName: "template_name",
        },
        {
          label: "总分",
          slotName: "total_score",
        },
        {
          label: "接收范围",
          slotName: "reception_range",
        },
        {
          label: "发布人",
          slotName: "publish_name",
        },
        {
          label: "权重",
          slotName: "weights",
        },
        {
          label: "得分",
          slotName: "score",
        },
      ],//合并报表的列list
      tableData: [],//合并报表的数据
    }
  },
  watch: {
    detailData: {
      immediate: true,
      handler(val){
        if(val){
          this.tableData = val.detailList ? JSON.parse(JSON.stringify(val.detailList)) : []
        }
      },
      deep: true
    }
  },
  methods: {
    //获取列表数据
    getList(){

    },
    //导出
    exportMergeTask(){
      this.$emit("exportTable")
    },
    //处理计算
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      let score = 0
      console.log(columns)
      columns.forEach((column, index) => {
        if (index === 0 || index === 1 || index === 2 || index === 4 || index === 5 || index === 6 ) {
          sums[index] = '';
          return;
        }
        if (index === 3) {
          let totalSum = 0
          this.tableData.map((item) => {
            totalSum += Number(item.total_score)
          })
          sums[index] = totalSum;
          return;
        }
        if (index === 7) {
          let sum = 0
          this.tableData.map((item) => {
            sum += Number(item.score)
          })
          sums[index] = sum;
          return;
        }
      });
      console.log(sums)

      return sums;
    },
  }
}
</script>
<style lang="scss" scoped>
.contain {
  width: unset !important;
  background-color: #ffffff;
  height: calc(100% - 64px);
  padding: 34px 30px 30px 30px;
  .mb{
    margin-bottom: 20px;
  }
  /deep/.el-table {
    // margin-top: 14px;
  }
  /deep/.el-table th > .cell {
    padding-left: 14px;
    border-left: 2px solid #e4ebf5;
  }
  /deep/.el-table th:first-child > .cell {
    border-left: transparent;
  }
  /deep/.el-table__footer-wrapper tbody td{
    color: #C6000B;
    font-size: 14px;
  }
  /deep/.el-table__fixed-footer-wrapper tbody td{
    color: #003685;
  }
  .form{
    width: 100%;
  }
  .table-handle{
    margin-right: 10px;
    margin-left: 0;
  }
  .tableList {
    width: 100%;
    height: calc(100% - 195px);
  }
}
</style>