<template>
  <div class="contain">
    <div class="header-title">
      <!-- <span>{{ pageTitle }}</span>
      <el-button class="return-button" @click="onCancel">返回</el-button> -->
      <el-page-header
        @back="onCancel"
        :content="pageTitle"
      >
        <template slot="title">
          <i class="el-icon-arrow-left"></i><span>返回</span>
        </template>
      </el-page-header>
    </div>
    <div
      class="form"
      v-if="showFlag == '1'"
    >
      <el-form
        :model="mergeTaskForm"
        style="width: 80%"
        :rules="rules"
        ref="mergeTaskForm"
        :label-width="labelWidth"
        class="demo-ruleForm"
      >
        <el-form-item
          label="层级:"
          prop="showLevel"
          ref="showLevel"
        >
          <el-select
            v-model="mergeTaskForm.showLevel"
            class="elInput"
            placeholder="请选择"
            @change="handleShowLevelChange"
          >
            <el-option
              v-for="item in levelOptions"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="合并督导主题:"
          prop="topicList"
          ref="topicList"
        >
          <div
            class="merge-info"
            v-for="(mergeInfo, index) in mergeTaskForm.topicList"
            :key="index"
          >
            <div class="option">
              <el-select
                v-model="mergeInfo.topicId"
                class="elInput"
                placeholder="请选择"
                @change="(val) => handleTaskNameChange(val, mergeInfo, index)"
              >
                <el-option
                  v-for="item in mergeInfo.topicOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  :disabled="!!mergeTaskForm.topicList.find((val,itemIndex) => val.topicId == item.id && index !== itemIndex)"
                >
                </el-option>
              </el-select>
            </div>
            <div class="option">
              <span>权重：</span>
              <el-input-number
                v-model="mergeInfo.weights"
				class="elInput"
				:max="100"
				:min="0"
				style="width: 7.8125vw"
                clearable
              ></el-input-number>
            </div>
            <div class="option">
              <el-button
                type="primary"
                icon="el-icon-plus"
                @click="handleMergeTaskFun('add', mergeInfo, index)"
              ></el-button>
              <el-button
                type="primary"
                icon="el-icon-minus"
                @click="handleMergeTaskFun('del', mergeInfo, index)"
                v-if="mergeTaskForm.topicList.length > 1"
              ></el-button>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div class="form-btn">
        <el-button
          class="cancel"
          @click="onCancel"
          :loading="buttonLoading"
        >取消</el-button>
        <el-button
          class="submit"
          @click="onSubmit"
          :loading="buttonLoading"
        >合并</el-button>
      </div>
    </div>
    <div
      class="form table"
      v-if="showFlag == '2'"
    >
      <div class="table-button">
        <el-button
          type="primary"
          class="sheet-btn"
          @click="exportMergeTask"
          icon="el-icon-plus"
        >导出</el-button>
      </div>
      <l-table
        v-if="isShowTable"
        :columns="columns"
        :dataSource="tableData"
        :pagination="false"
        :loading="tableLoading"
        :showXh="true"
        :height="'calc(100% - 2.91667vw)'"
        :autoHeight="false"
      >
        <el-table-column
          prop="name"
          label="名称"
          align="center"
          width="200"
          slot="name"
        ></el-table-column>
        <template v-for="item in columnsList">
          <el-table-column
            :prop="item.columnsTaskName"
            label="督导主题"
            align="center"
            min-width="240"
            :slot="item.columnsTaskName"
            :key="item.columnsTaskName"
          ></el-table-column>
          <el-table-column
            :prop="item.columnsTotalScoreName"
            label="总分"
            align="center"
            width="50"
            :slot="item.columnsTotalScoreName"
            :key="item.columnsTotalScoreName"
          ></el-table-column>
          <el-table-column
            :prop="item.columnsScoreName"
            label="得分(总部评分)"
            align="center"
            width="140"
            sortable
            :slot="item.columnsScoreName"
            :key="item.columnsScoreName"
          ></el-table-column>
          <el-table-column
            :prop="item.columnsWeightName"
            label="权重"
            align="center"
            width="60"
            :slot="item.columnsWeightName"
            :key="item.columnsWeightName"
          >
            <!-- {{ item.columnsWeightName }}% -->
            <template slot-scope="{ row }">
              <span v-if="row[item.columnsWeightName]==undefined"></span>
              <span v-else> {{ row[item.columnsWeightName] + "%" }} </span>
            </template>
          </el-table-column>
        </template>
        <el-table-column
          prop="mergeScore"
          label="合并得分"
          fixed="right"
          width="100"
          align="center"
          slot="mergeScore"
          sortable
        ></el-table-column>
        <el-table-column
          prop="handle"
          label="操作"
          fixed="right"
          width="90"
          align="center"
          slot="handle"
        >
          <template slot-scope="scope">
            <el-button
              class="table-handle"
              @click="handleReportFun('view', scope.row)"
              type="text"
            >查看</el-button>
          </template>
        </el-table-column>
      </l-table>
    </div>
    <el-drawer
      :title="drawerTitle"
      :append-to-body="true"
      :destroy-on-close="true"
      :visible.sync="drawerVisible"
      :direction="'rtl'"
      :size="'60%'"
      :before-close="handleDrawerClose"
    >
      <merge-task-detail
        :detailData="mergeTopicData"
        @exportTable="handleDetailExport"
      ></merge-task-detail>
    </el-drawer>
  </div>
</template>
<script>
import MergeTaskDetail from "./showMergeTaskDetail.vue";
import LTable from "@/components/tableComponent/index.vue";
import downloadFile from "@/utils/downloadFile";
export default {
  name: "superviseReport",
  components: { MergeTaskDetail, LTable },
  data () {
    var validateMergeTopicList = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请添加要合并的督导主题"));
      } else if (value.length > 0) {
        let errorList = [];
        value.map((item, i) => {
          let errorInfo = "";
          if (item.topicId !== "" && item.weights === "") {
            errorInfo = "第" + (i + 1) + "行的权重不能为空";
          } else if (item.topicId === "" && item.weights !== "") {
            errorInfo = "第" + (i + 1) + "行的督导主题不能为空";
          } else if (item.topicId == "" && item.weights == "") {
            errorInfo = "第" + (i + 1) + "行的督导主题和权重不能为空";
          }
          if (errorInfo) {
            errorList.push(errorInfo);
          }
        });
        if (errorList.length > 0) {
          callback(new Error(errorList.join(",")));
        } else {
          callback();
        }
      }
    };
    return {
      pageTitle: "合并任务", //页面标题
      showFlag: "1", //展示内容标识
      mergeTaskForm: {
        showLevel: "",
        topicList: [],
      }, //表单数据
      rules: {
        showLevel: [
          { required: true, message: "请选择层级", trigger: "change" },
        ],
        topicList: [
          {
            required: true,
            validator: validateMergeTopicList,
            trigger: "change",
          },
        ],
      },
      levelOptions: [
        {
          name: "园校",
          value: "1",
        },
        {
          name: "分公司",
          value: "2",
        },
        {
          name: "总部",
          value: "3",
        },
      ], //层级下拉
      chooseSuperviseOptions: [], //督导主题下拉list
      buttonLoading: false, //按钮loading
      columns: [], //合并报表的列list
      tableData: [], //合并报表的数据
      isShowTable: false, //是否展示table
      tableLoading: false, //列表loading
      columnsList: [], //请求回来的列展示数据

      drawerTitle: "详情", //抽屉标题
      drawerVisible: false, //抽屉显隐标识
      mergeTopicData: {}, //详情

      labelWidth: "130px",
    };
  },
  created () {
    let chooseMenuInfoList = sessionStorage.getItem("chooseMenuInfoList")
      ? JSON.parse(sessionStorage.getItem("chooseMenuInfoList"))
      : null;
    if (chooseMenuInfoList) {
      chooseMenuInfoList.map((item) => {
        if (item.path == this.$route.path) {
          item.meta.title = this.pageTitle;
        }
      });
      let breadCrumbList = JSON.parse(JSON.stringify(chooseMenuInfoList));
      breadCrumbList.shift();
      console.log(breadCrumbList);
      this.$store.commit("common/getBreadcrumbList", breadCrumbList || []);
    }
    this.getSuperviseOptions();
  },
  mounted () {
    window.addEventListener("resize", (e) => {
      if (e.target.innerWidth < 1300) {
        this.labelWidth = "90px";
      } else {
        this.labelWidth = (130 / 1920) * e.target.innerWidth + "px";
      }
      this.$forceUpdate();
    });
    if (window.innerWidth < 1300) {
      this.labelWidth = "90px";
    } else {
      this.labelWidth = (130 / 1920) * window.innerWidth + "px";
    }
  },
  methods: {
    //层级改变事件
    handleShowLevelChange (val) { },
    //合并督导主题操作事件
    handleMergeTaskFun (type, info, index) {
      let list = JSON.parse(JSON.stringify(this.mergeTaskForm.topicList));
      let beforeList = [];
      let afterList = [];
      let resultList = [];
      if (type == "add") {
        for (let i = 0; i <= index; i++) {
          beforeList.push(list[i]);
        }
        for (let i = index + 1; i < list.length; i++) {
          afterList.push(list[i]);
        }
        let info = {
          topicId: "",
          topicOptions: JSON.parse(JSON.stringify(this.chooseSuperviseOptions)),
          weights: "50",
        };
        beforeList.push(info);
        resultList = [...beforeList, ...afterList];
        this.mergeTaskForm.topicList = resultList;
      } else if (type == "del") {
        list.splice(index, 1);
        this.mergeTaskForm.topicList = list;
      }
    },
    //选择督导主题
    handleTaskNameChange (val, info, index) {
      let mergeInfo = JSON.parse(JSON.stringify(info));
      let optionInfo = this.chooseSuperviseOptions.find((superviseInfo) => {
        return superviseInfo.id == val;
      });
      mergeInfo.topicName = optionInfo.name;
      this.$set(this.mergeTaskForm.topicList, index, mergeInfo);
    },
    //取消
    onCancel () {
      this.$router.back(-1);
    },
    //合并事件
    onSubmit () {
      this.$refs.mergeTaskForm.validate((valid, object) => {
        if (valid) {
          // console.log(this.mergeTaskForm);
          let params = JSON.parse(JSON.stringify(this.mergeTaskForm));
          let topicNum = 0;
          let sameTopic = false;

          params.topicList.map((item) => {
            topicNum += Number(item.weights)
            //   if (topicId === "") {
            //     topicId = item.topicId;
            //   } else if (topicId == item.topicId) {
            //     sameTopic = true;
            //   }
            //   delete item.topicOptions;

          });

          if (topicNum > 100) {
            this.$message.error("权重值不可超过100，请修改");
            return;
          }

          // if (sameTopic) {
          //   this.$message.error("有重复选择主主题，请修改");
          //   return;
          // }

          this.buttonLoading = true;
          this.$api
            .saveMergeTopic(params)
            .then((res) => {
              if (res.data.code == 0) {
                this.buttonLoading = false;
                this.showFlag = "2";
                this.pageTitle = "合并任务/合并报表";
                let chooseMenuInfoList = sessionStorage.getItem(
                  "chooseMenuInfoList"
                )
                  ? JSON.parse(sessionStorage.getItem("chooseMenuInfoList"))
                  : null;
                if (chooseMenuInfoList) {
                  chooseMenuInfoList.map((item) => {
                    if (item.path == this.$route.path) {
                      item.meta.title = this.pageTitle;
                    }
                  });
                }
                let breadCrumbList = JSON.parse(
                  JSON.stringify(chooseMenuInfoList)
                );
                breadCrumbList.shift();
                console.log(breadCrumbList);
                this.$store.commit(
                  "common/getBreadcrumbList",
                  breadCrumbList || []
                );
                this.getMergeList(res.data.data);
              } else {
                this.buttonLoading = false;
                this.$message.error(res.data.msg);
              }
            })
            .catch(() => {
              this.buttonLoading = false;
            });
        } else {
          for (let i in object) {
            let dom = this.$refs[i];
            dom.$el.scrollIntoView({
              //滚动到指定节点
              block: "center", //值有start,center,end，nearest，当前显示在视图区域中间
              behavior: "smooth", //值有auto、instant,smooth，缓动动画（当前是慢速的）
            });
            break;
          }
          return false;
        }
      });
    },
    //生成的合并报表导出事件
    exportMergeTask () {
      let url = "/supervise/report/exportMerge";
      let params = JSON.parse(JSON.stringify(this.mergeTaskForm));
      params.topicList.map((item) => {
        delete item.topicOptions;
      });
      downloadFile({ url, data: params, type: "post" });
    },
    //合并任务报表处理事件
    handleReportFun (type, row) {
      this.drawerVisible = true;
      this.$nextTick(() => {
        this.mergeTopicData = row;
      });
    },
    //处理详情导出
    handleDetailExport () {
      let url = "/supervise/report/exportMergeDetail";
      let params = JSON.parse(JSON.stringify(this.mergeTaskForm));
      params.topicName = this.mergeTopicData.name;
      params.topicList.map((item) => {
        delete item.topicOptions;
      });
      downloadFile({ url, data: params, type: "post" });
    },
    //获取督导主题列表
    getSuperviseOptions () {
      this.$api
        .getTopicList({})
        .then((res) => {
          if (res.data.code == 0) {
            console.log(res.data.data);
            this.chooseSuperviseOptions = res.data.data;
            let info = {
              topicId: "",
              topicOptions: JSON.parse(JSON.stringify(res.data.data)),
              weights: "50",
            };
            this.mergeTaskForm.topicList.push(info);
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => { });
    },
    //获取合并报表
    getMergeList (list) {
      this.tableLoading = true;
      this.isShowTable = false;
      setTimeout(() => {
        this.tableLoading = false;
        let columnLength = this.mergeTaskForm.topicList.length;
        let columnList = [];
        for (let i = 0; i < columnLength; i++) {
          let info = {
            columnsTaskName: i + 1 + "supervision_topic",
            columnsTotalScoreName: i + 1 + "total_score",
            columnsScoreName: i + 1 + "score",
            columnsWeightName: i + 1 + "weights",
          };
          columnList.push(info);
        }
        let column = [
          {
            label: "组织",
            slotName: "name",
          },
        ];
        columnList.map((item) => {
          let tableInfo = {};
          for (let key in item) {
            console.log(key);
            let info = {
              label: "",
              slotName: "",
            };
            switch (key) {
              case "columnsTaskName":
                info.label = "督导主题";
                info.slotName = item[key];
                break;
              case "columnsTotalScoreName":
                info.label = "总分";
                info.slotName = item[key];
                break;
              case "columnsScoreName":
                info.label = "得分";
                info.slotName = item[key];
                break;
              case "columnsWeightName":
                info.label = "权重";
                info.slotName = item[key];
                break;
            }
            column.push(info);
          }
        });
        column.push({ label: "合并得分", slotName: "mergeScore" });
        column.push({ label: "操作", slotName: "handle" });
        let tableDataList = [];
        list.map((item) => {
          let tableInfo = {
            name: item.name,
            mergeScore: item.score,
            detailList: item.detailList || [],
          };
          if (item.topicList && item.topicList.length > 0) {
            item.topicList.map((topicInfo) => {
              tableInfo = Object.assign(tableInfo, topicInfo);
            });
          }
          tableDataList.push(tableInfo);
        });
        this.columnsList = columnList;
        console.log(this.columnsList, "zhanshidelieshuju");
        this.columns = column;
        this.isShowTable = true;
        this.$nextTick(() => {
          this.tableData = tableDataList;
          console.log(this.tableData, "zhanshidelieshujuyyyyyy7777");
        });
      }, 1000);
    },
    //关闭抽屉弹窗
    handleDrawerClose () {
      this.drawerVisible = false;
      this.$nextTick(() => {
        this.mergeTopicData = {};
      });
    },
  },
  beforeDestroy () {
    window.removeEventListener("resize", (e) => {
      if (e.target.innerWidth < 1300) {
        this.labelWidth = "90px";
      } else {
        this.labelWidth = (130 / 1920) * e.target.innerWidth + "px";
      }
      this.$forceUpdate();
    });
  },
};
</script>
<style lang="scss" scoped>
.contain {
  width: calc(100% - 80px);
  height: calc(100% - 64px);
  padding: 34px 30px 30px 30px;
  .header-title {
    width: 100%;
    color: #3d3e4f;
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .return-button {
      &.el-button:focus {
        color: #222222;
        border-color: #b5bdcf;
        background-color: rgba(52, 85, 236, 0.1);
      }
      &.el-button:hover {
        color: #222222;
        border-color: #b5bdcf;
        background-color: rgba(52, 85, 236, 0.1);
      }
    }
  }
  /deep/.el-radio__input.is-checked .el-radio__inner {
    border-color: #003685;
    background: #003685;
  }

  /deep/.el-radio__input.is-checked + .el-radio__label {
    color: #003685;
  }

  /deep/.el-radio {
    font-size: 14px;
    font-weight: 400;
    color: #010334;
  }
  .dashed-line {
    width: 100%;
    height: 0;
    border-bottom: 1px dashed #e2e7f0;
    margin: 40px 0;
  }
  .form {
    width: calc(100% - 80px);
    padding: 0 40px;
    min-height: 696px;
    &.table {
      height: calc(100% - 64px);
      .table-button {
        margin-bottom: 20px;
      }
      .tableList {
        height: calc(100% - 60px);
      }
    }
    .form-title {
      width: 100%;
      color: #3d3e4f;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 40px;
    }
    /deep/.el-form-item .el-select {
      width: 100%;
      height: 40px;
    }

    /deep/.el-form-item__label {
      text-align: left;
      font-size: 16px;
      font-weight: 400;
      color: #606266;
    }
    /deep/.el-input--suffix .el-input__inner {
      padding-right: 30px;
    }
    /deep/.el-input--suffix .el-input__inner,
    /deep/.el-textarea__inner {
      font-size: 14px;
      font-weight: 400;
      color: #010334;
    }

    /deep/.el-textarea__inner {
      height: 80px;
    }
    /deep/.el-switch__label.is-active {
      color: #010334;
    }
    /deep/.el-switch__label {
      color: #010334;
    }
    /deep/.el-checkbox__input.is-checked + .el-checkbox__label {
      color: #003685;
    }
    /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #003685 !important;
      border-color: #003685 !important;
    }
    /deep/.el-checkbox__inner:hover {
      border-color: #003685 !important;
    }
    /deep/.el-checkbox__label {
      color: #010334 !important;
    }
    .merge-info {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .option {
        display: flex;
        align-items: center;
        margin-right: 20px;
        span {
          width: 60px;
        }
        .el-input {
          width: calc(100% - 80px);
        }
      }
    }
  }

  .form-btn {
    margin-top: 43px;
    display: flex;
    padding-left: 130px;

    .cancel {
      width: 137px;
      height: 40px;
      border: 1px solid #b5bdcf;
      border-radius: 4px;
      font-size: 14px;
      line-height: 14px;
      color: #222222;
      opacity: 1;
    }

    .submit {
      display: block;
      width: 137px;
      height: 40px;
      background: #003685;
      box-shadow: 0px 3px 10px rgba(52, 85, 236, 0.4);
      opacity: 1;
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
    }

    /deep/.el-button + .el-button {
      margin-left: 20px;
    }
  }
}
</style>